import React from 'react'
import './Header.css';



export default function Nav() {
    return (
        <div>
            
            <nav className='navigation' style={{width:'100%'}}>
                 <a  className='nav' href='../landingpage'>Home<i className="fas fa-home"></i></a>
                <a className='nav' href='../aboutme/aboutme' target="_blank">About Me<i class="far fa-user-circle"></i></a>
                <a className='nav' href='../resume/resume' target="_blank">Resume<i class="fad fa-file"></i></a>
                <a className='nav' href='../projects/project' target="_blank">Projects<i class="fas fa-project-diagram"></i></a>
                <a className='nav' href='../contact/contact' target="_blank">Contact<i class="fas fa-id-card"></i></a>
                
            </nav>
       </div>
    )
}
