import React from 'react'

export default function Resume() {
    return (
        <div>
            <article>
            <h1>IT SCHOOL FULGENCIO OROZ </h1>
                <p>Sep 2002 to Junio 2006 HABANA CUBA </p>
                <hr style={{borderTop:'3px solid #fff',opacity:''}}/>
                <h1>ThinkFull</h1>
                <p>Oct 2020  to March 2021 </p>
            </article>
        </div>
    )
}
