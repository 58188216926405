import React from 'react';
import work from './work.jpg'
import './sections.css'


export default function Section() {
    return (
        <div>
            <section className='sect' style={{display:'flex',justifyContent:'center',margin:'10px'}}>
                
                <div>
                  <img style={{width:'200px'}} src={work} alt=''/>
                  
                </div>
            </section>
        </div>
    )
}
