import React from 'react'

export default function Projects() {
    return (
        <div>

            
           
            <ol><li>Projects</li>
                <li><a  href='https://final-robots.netlify.app/'>Robots</a></li>
                <li><a href='https://barbaro-store.netlify.app/'>Ecomerce</a></li>
                <li><a href='https://elegant-roentgen-892ece.netlify.app/'>Tables</a></li>
                <li><a href='https://boxs.netlify.app/'>Boxs</a></li>
                <li><a href ='https://pedantic-fermat-3994ab.netlify.app/'>Responsive</a></li>
                <li><a href ='https://quizzical-shockley-efb80c.netlify.app/'>Color Change</a></li>
            </ol> 
        </div>
    )
}
