import React from 'react'

export default function Contact() {
    return (
        <div style={{border:'1px solid #ffff'}}>
             <div> <p style={{width:'75%',margin:'auto',paddingTop:'1em',color:'#ffff'}}>With a huge desire to bring smart ideas to the Digital World</p></div>
         <ul  style={{ color:'#ffff',margin:'10px'}}>
             <li><i className="fas fa-phone-square">(512)6086054</i></li>
             <li><i class="fas fa-envelope-square"><a href='barbarotrabajo@gmail.com'><p><a href="mailto:barbarotrabajo@gmail.com?subject=Email%20Subject%20Here">Email Me</a></p></a></i></li>
             <hr/>
             <li><i class="fab fa-github"><a href ='https://github.com/barbaroreyes'>https://github.com/barbaroreyes</a></i></li>
             <li> <i className=" link fab fa-linkedin"><a href='https://www.linkedin.com/in/barbaro-reyes-b023aa1a7/'>https://www.linkedin.com/in/barbaro-reyes-b023aa1a7/</a></i></li>
             <li><i className=" fab fa-linkedin"></i></li>
         </ul> 
        </div>
    )
}
