import React from 'react';
import './index.css'

export default function Footer() {
    return (
        <div>
            <h3>@barbarito ThinkFull </h3>
        </div>
    )
}
