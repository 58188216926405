import React from 'react';
import Main from './componet/main/main'
import './App.css';
import Nav from './componet/navigati/nav';
import  Footer from './componet/footer/index';
import  Section from './componet/section/section';




function App() {
  return (
    <div className="App " >  
        <Nav/> 
        <Section/> 
        <Main/>  
        <Footer/>           
    </div>
  );
}

export default App;
