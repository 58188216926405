import React, { Component } from 'react';
import  {Switch,Route} from 'react-router-dom';
import LandingPage from '../landingpage/landingPage';
import  About from '../aboutme/aboutMe';
import Contact from '../contact/contact';
import  Resume from '../resume/resume';
import  Projects from '../projects/project'






export default class Main extends Component {
    render() {
        return (
            <main >
                 <Switch>
                <Route exact  path="/"  component = {LandingPage}/>   
                <Route        path='/aboutMe'      component = {About}/>
                <Route        path='/contact'      component = {Contact}/> 
                <Route        path='/projects'      component = {Projects}/>
                <Route        path='/resume'       component = {Resume}/>             
               </Switch>
             
            </main>
        )
    }
}
